<template>
  <div
    class="darkertext"
  >
    <v-card id="account-setting-card">
      <StudentInfosForm
        :formations="formations"
        :tuteurs="tuteurs"
        :partenaires="partenaires"
      ></StudentInfosForm>
    </v-card>
  </div>
</template>

<script>

import StudentInfosForm from './StudentInfosForm.vue'

export default {
  components: {
    StudentInfosForm,
  },
  data() {
    return {
      loading: false,
      tuteurs: [],
      partenaires: [],
      formations: [],
    }
  },
  watch: {
    $route: 'fetchData',

  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchFormations(), this.fetchTuteurs(), this.fetchPartenaires()])
        .then(values => {
          [this.formations, this.tuteurs, this.partenaires] = values
        })
        .catch(err => console.log(err))
        .finally(this.loading = false)
    },
    async fetchFormations() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/formations`)

      return res.data
    },
    async fetchTuteurs() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/tuteurs`)
      console.log(res.data)

      return res.data
    },
    async fetchPartenaires() {
      const res = this.$http.get(`${process.env.VUE_APP_API_URL}/partenaireSimple`)

      return res.data
    },
  },
  setup() {
    return {
    }
  },
}
</script>
<style lang="scss">
@import '~@/styles/studentnew.scss';
</style>
